import * as React from "react"
import { useForm, Controller } from "react-hook-form"
import { navigate } from "gatsby"

import { errorStyles, encodeWithFiles } from "../helpers"

import Dropzone from "../components/ui/dropzone"
import ErrorMessage from "./ui/error-message"
import ButtonWithLoader from "./ui/button-with-loader"

const FormContact = ({ jobTitle }) => {
  const [alert, setalert] = React.useState(null)
  const [isLoading, setisLoading] = React.useState(false)
  const formEl = React.useRef(null)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" })

  const onSubmit = (data, e) => {
    e.preventDefault()
    const form = e.target

    setisLoading(true)

    fetch("/", {
      method: "POST",
      body: encodeWithFiles({
        "form-name": form.getAttribute("name"),
        ...data,
      }),
    })
      .then(() => {
        navigate("/job-application-thank-you")
      })
      .catch(error => {
        setalert("Form could not be submitted. Please try again.")
        setisLoading(false)
        formEl.current.reset()
        console.log(error)
      })
  }

  return (
    <div className="relative">
      {alert && <p>{alert}</p>}

      <form
        ref={formEl}
        name="job-application"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit(onSubmit)}>
        <div hidden>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </div>

        <input
          type="hidden"
          name="job-title"
          defaultValue={jobTitle}
          readOnly
          {...register("jobTitle")}
        />

        <fieldset>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            style={errors.name && errorStyles}
            {...register("name", { required: true, maxLength: 80 })}
          />
          {errors.name?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            name="email"
            id="email"
            style={errors.email && errorStyles}
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.email?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="notes">Notes</label>
          <textarea
            type="text"
            name="notes"
            id="notes"
            style={errors.notes && errorStyles}
            {...register("notes", { required: true })}
          />
          {errors.notes?.type === "required" && <ErrorMessage />}
        </fieldset>

        <fieldset>
          <label htmlFor="attachment">Attachment</label>
          <Controller
            name="attachment"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Dropzone
                multiple={false}
                maxSize={1000000}
                accept=".pdf, .doc, .docx"
                onChange={e => onChange(e.target.files[0])}
              />
            )}
          />
          {errors.attachment?.type === "required" && <ErrorMessage />}
        </fieldset>

        <ButtonWithLoader label="Apply" isLoading={isLoading} />
      </form>
    </div>
  )
}

export default FormContact

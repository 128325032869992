import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "../components/ui/grid"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import FormJobApplication from "../components/form-job-application"

import defaultThumbnail from "../images/thumbnail-loadmill.png"

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <GatsbyImage
        image={node?.data?.target?.gatsbyImageData}
        alt={node?.data?.target?.title}
        className="my-12"
      />
    ),
  },
}

const JobOpeningContentfulTemplate = ({ data, location }) => {
  const job = data.contentfulJobOpening
  const content = data.contentfulJobOpening?.content || ""

  return (
    <Layout location={location}>
      <Seo
        title={job.title}
        description={job.description || job.excerpt}
        image={defaultThumbnail}
      />

      <Container className="mb-32 mt-12 lg:mt-32">
        <Row>
          <Col size="w-full lg:w-3/5">
            <article
              className="styled-content"
              itemScope
              itemType="http://schema.org/Article">
              <header>
                <h1 className="mb-8">{job.title}</h1>
                <div className="mb-8 text-lg font-medium text-gray-dim">
                  {job.location.title}
                </div>
              </header>

              <section itemProp="articleBody" className="prose max-w-full">
                {content && renderRichText(content, options)}
              </section>
            </article>
          </Col>

          <Col
            size="w-full lg:w-2/5"
            className="lg:sticky lg:top-24 lg:self-start">
            <aside className="lg:pl-20">
              <div className="p-10 bg-gray-light rounded-xl">
                <FormJobApplication jobTitle={job.title} />
              </div>
            </aside>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default JobOpeningContentfulTemplate

export const pageQuery = graphql`
  query JobOpeningContentfulBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulJobOpening(slug: { eq: $slug }) {
      title
      description
      slug
      content {
        raw
      }
      location {
        title
        slug
      }
    }
  }
`

import * as React from "react"
import { useDropzone } from "react-dropzone"

const Dropzone = ({ multiple, maxSize, accept, onChange }) => {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ multiple: multiple, maxSize: maxSize, accept: accept })

  const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>)

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#606060",
    borderStyle: "dashed",
    backgroundColor: "#fff",
    color: "#606060",
    outline: "none",
    cursor: "pointer",
    transition: "border .24s ease-in-out",
  }

  const activeStyle = {
    borderColor: "#5d96fc",
  }

  const acceptStyle = {
    borderColor: "#0eb08d",
  }

  const rejectStyle = {
    borderColor: "#db638f",
  }

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isDragActive,
      isDragReject,
      isDragAccept,
      activeStyle,
      acceptStyle,
      rejectStyle,
    ]
  )

  return (
    <React.Fragment>
      <div {...getRootProps({ style })}>
        <input
          name="attachment"
          id="attachment"
          {...getInputProps({ onChange })}
        />

        <p className="mb-2 text-sm">
          Drag 'n' Drop file here, or click to select a file.
        </p>

        <em className="text-xs">(Accepted file types: .pdf .docx .doc)</em>
        <em className="text-xs">(Max file size: 1MB)</em>
      </div>

      <ul className="list-none p-0 mt-3 text-sm font-bold">{files}</ul>
    </React.Fragment>
  )
}

export default Dropzone
